// Download Page Styles

.download-page {
  .get-srsran-row {
    background: lighten($gray240,2%);
    .content-wrapper {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
              justify-content: space-between;
      h4 {
        color: $srs-deep-blue;
      }
      .left-col, .right-col {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
      }
      .left-col {
        flex-basis: 60%;
        .get-srsran-card {
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
                  align-items: center;
          -webkit-box-flex: 1;
                  flex-grow: 1;
          color: $srs-blue;
          &:after {
            content: '';
            display: inline-block;
            height: 2rem;
            width: 2rem;
            min-width: 2rem;
            min-height: 2rem;
            background-image: url(../images/link-arrow-dark.svg);
            background-size: 2rem;
            background-position: center center;
            margin-left: 2rem;
          }
          .img-wrapper {
            width: 160px;
            height: 160px;
          }
          svg {
            width: 160px;
            height: 160px;
          }
          .text-wrapper {
            margin-left: 3rem;
            -webkit-box-flex: 1;
                    flex-grow: 1;
          }
        }
      }
      .right-col {
        flex-basis: 37%;
        .get-source-card {
          display: -webkit-box;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
                  flex-direction: column;
          -webkit-box-flex: 1;
                  flex-grow: 1;
          -webkit-box-pack: justify;
                  justify-content: space-between;
          padding: 0;
          color: $srs-blue;
          .source-section {
            display: -webkit-box;
            display: flex;
            -webkit-box-flex: 1;
                    flex-grow: 1;
            text-decoration: none;
            color: inherit;
            padding: 1rem;
            -webkit-box-align: center;
                    align-items: center;
            & ~ .source-section {
              border-top: 1px solid $gray210;
            }
            &:after {
              content: '';
              display: inline-block;
              height: 2rem;
              width: 2rem;
              min-width: 2rem;
              min-height: 2rem;
              background-image: url(../images/link-arrow-dark.svg);
              background-size: 2rem;
              background-position: center center;
              margin-left: .5rem;
            }
            .img-wrapper {
              width: 50px;
              height: 50px;
            }
            svg {
              width: 50px;
              height: 50px;
            }
            .text-wrapper {
              margin-left: 2rem;
              font-size: .9rem;
              -webkit-box-flex: 1;
                      flex-grow: 1;
              h4 {
                font-size: 1.1rem;
              }
            }
            &:hover {
              background: rgba(0,0,0,0.04);
            }
          }
        }
      }
    }
  }
  .get-stuck-row {
    h3 {
      .additional-text {
        font-size: 1.2rem;
        font-weight: 300;
        font-style: italic;
      }
    }
    .developing-cards-wrapper {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
              justify-content: space-between;
      .developing-card {
        flex-basis: 25%;
        -webkit-box-flex: 1;
                flex-grow: 1;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        &:after {
          content: '';
          display: inline-block;
          height: 2rem;
          width: 2rem;
          min-width: 2rem;
          min-height: 2rem;
          background-image: url(../images/link-arrow-dark.svg);
          background-size: 2rem;
          background-position: center center;
          margin-left: .5rem;
        }
        h4 {
          font-size: 1rem;
          margin-left: 1rem;
          color: $srs-blue;
          -webkit-box-flex: 1;
                  flex-grow: 1;
        }
        & ~ .developing-card {
          margin-left: 1rem;
        }
        .img-wrapper {
          width: 50px;
          height: 50px;
        }
        svg {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

// Responsive Adjustments

@media (max-width: 910px) {
.download-page {
    .get-stuck-row {
      .content-wrapper {
        .developing-cards-wrapper {
          flex-wrap: wrap;
          -webkit-box-pack: center;
                  justify-content: center;
          margin: -.5rem;
          .developing-card {
            flex-basis: 20%;
            margin: .5rem;
            -webkit-box-flex: 1;
                    flex-grow: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .download-page {
    .get-srsran-row .content-wrapper {
      flex-wrap: wrap;
      .left-col, .right-col {
        flex-basis: 80%;
        -webkit-box-flex: 1;
                flex-grow: 1;
      }
      .right-col {
        margin-top: 3rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .download-page {
    .get-srsran-row .content-wrapper {
      .left-col .get-srsran-card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
        -webkit-box-align: stretch;
                align-items: stretch;
        .img-wrapper {
          width: 100%;
          height: 100%;
        }
        svg {
          margin: 0 auto;
          width: 100px;
          height: 100px;
        }
        .text-wrapper {
          margin-left: 0;
          margin-top: 2rem;
          position: relative;
          padding-right: 2.5rem;
          &:after {
            content: '';
            display: inline-block;
            height: 2rem;
            width: 2rem;
            min-width: 2rem;
            min-height: 2rem;
            background-image: url(../images/link-arrow-dark.svg);
            background-size: 2rem;
            background-position: center center;
            margin-left: .5rem;
            position: absolute;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
          }
        }
        &:after {
          display: none;
        }
      }
    }
  }
}
