.post-body {
  .name-change-topbar {
    display: none;
  }
  header.has-topbar {
    margin-top: 0;
  }
}

.post-page {
  .post-heading-row {
    text-align: center;
    padding: 8rem 2rem 3rem;
    color: white;
    background-image: url(../images/hero-image.png);
    background-size: cover;
    background-position: center center;
    background-color: $srs-deep-blue;
    position: relative;
    h2 {
      position: relative;
      z-index: 3;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.15);
      z-index: 1;
    }
  }
  .main-content {
    max-width: 680px;
    margin: 2rem auto 0;
    background: white;
    padding: 3rem;
    color: $dark;
    border-radius: $radius;
    top: -3.5rem;
    position: relative;
    z-index: 2;
    @include box-shadow(6px, 0.05);
    line-height: 1.4;
    img {
      width: 100%;
      border-radius: $radius;
      margin-top: -0.5rem;
    }
    .post-data {
      font-size: 13px;
      color: $srs-gray;
      margin-bottom: 1.5rem;
    }
    ul {
      list-style-type: disc;
      padding-left: 1rem;
      margin-bottom: 1rem;

      li ~ li {
        margin-top: 0.35rem;
      }
    }
    @media (max-width: 520px) {
      padding: 1rem;
      img {
        margin-top: 0;
      }
    }
  }
}
