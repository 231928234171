// Research Page Styles

.research-articles {
  .content-wrapper {
    .paginator.swiper-container {
      width: 100%;
      height: auto;
      padding-bottom: 3rem;
      .paginator-wrapper {
        .paginator-page {
          display: -webkit-box;
          display: flex;
          flex-wrap: wrap;
          -webkit-box-pack: center;
          justify-content: center;
          .article-card {
            padding: 1rem;
            flex-basis: 20.5%;
            margin: 0.5rem;
            -webkit-box-flex: 1;
            flex-grow: 1;
            min-width: 250px;
            max-width: 400px;
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -webkit-box-align: stretch;
            align-items: stretch;
            -webkit-box-pack: flex-start;
            justify-content: flex-start;
            position: relative;
            .card-header {
              -webkit-box-flex: 1;
              flex-grow: 1;
              padding: 0.5rem 0.5rem 1rem;
              h4 {
                font-size: 0.9rem;
                color: $srs-deep-blue;
                font-weight: 500;
              }
            }
            .card-footer {
              border-top: 1px solid $gray210;
              margin: 0 -1rem;
              padding: 1rem 1.5rem 0.5rem;
              font-size: 0.8rem;
              flex-basis: 45px;
              -webkit-box-flex: 1;
              flex-grow: 1;
              position: relative;
              .footer-row ~ .footer-row {
                margin-top: 0.5rem;
              }
              .label {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 0.8rem;
                margin-bottom: 0.25rem;
              }
              .authors,
              .affiliation {
                font-weight: 300;
              }
            }
            &.with-hover .card-footer:after {
              content: "";
              position: absolute;
              width: 1.5rem;
              height: 1.5rem;
              border-radius: 2rem;
              right: 1rem;
              top: -0.75rem;
              display: block;
              border: 1px solid $srs-blue;
              background-color: white;
              background-image: url(../images/external-link-icon.svg);
              background-size: 0.75rem;
              background-position: center center;
              background-repeat: no-repeat;
            }
            .year {
              position: absolute;
              right: 0.25rem;
              bottom: 0.25rem;
              padding: 0.25rem;
              font-size: 0.7rem;
              background: rgba(0, 0, 0, 0.04);
              border-radius: $radius;
              font-weight: 400;
            }
          }
        }
      }
      .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        color: $dark;
        opacity: 1;
        background: rgba(0, 0, 0, 0.15);
      }
      .swiper-pagination-bullet-active {
        color: white;
        background: $srs-blue;
      }
    }
  }
}
