// News Page Styles

.news-page {
  .twitter-cards {
    position: relative;
    .tweets-wrapper {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      justify-content: center;
      margin-top: 2.5rem;
      position: relative;
      z-index: 2;
      twitter-widget.twitter-tweet {
        flex-basis: 40%;
        width: auto !important;
        margin: 0.5rem !important;
      }
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: -2rem;
      right: 10rem;
      width: 272px;
      height: 220px;
      background: url(../images/twitter-icon.svg);
    }
  }
  .news-cards {
    background: lighten($gray240, 2%);
    .paginator.swiper-container {
      width: 100%;
      height: auto;
      padding-bottom: 3rem;
      margin-top: 2.5rem;
      .paginator-wrapper {
        .paginator-page {
          display: -webkit-box;
          display: flex;
          flex-wrap: wrap;
          -webkit-box-pack: center;
          justify-content: center;
          .news-card {
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -webkit-box-flex: 1;
            flex-grow: 1;
            min-width: 200px;
            max-width: 340px;
            margin: 1rem;
            overflow: hidden;
            .image-wrapper {
              background-color: $srs-blue;
              margin: -2rem -2rem 1.5rem -2rem;
              text-align: center;
              flex-basis: 140px;
              overflow: hidden;
              position: relative;
              .news-image {
                width: 100%;
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
              }
            }
            .text-wrapper {
              color: $srs-gray;
              font-weight: 300;
              h4 {
                color: $srs-deep-blue;
                margin-bottom: 1rem;
                font-size: 1rem;
                font-weight: 500;
              }
              .description {
                margin-bottom: 1rem;
                font-style: italic;
              }
              .label {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 0.8rem;
                margin-bottom: 0.25rem;
              }
              .authors {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
      .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        color: $dark;
        opacity: 1;
        background: rgba(0, 0, 0, 0.15);
      }
      .swiper-pagination-bullet-active {
        color: white;
        background: $srs-blue;
      }
    }
  }
}
