// Commercial Page Styles

.about-srs {
  display: -webkit-box;
  display: flex;
  background: white;
  @include box-shadow(6px, 0.05);
  position: relative;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  .left-col {
    max-width: 450px;
    min-width: 300px;
    flex-basis: 28%;
    padding: 4rem;
    z-index: 2;
    position: relative;
    img {
      width: 200px;
      margin: 0 auto;
    }
  }
  .right-col {
    background: $srs-deep-blue;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-basis: 60%;
    padding: 4rem;
    color: white;
    .inner-wrapper {
      z-index: 2;
      position: relative;
    }
    h3 {
      margin-bottom: 2rem;
      &:after {
        content: '';
        display: block;
        height: 3px;
        width: 2.5rem;
        background: $srs-blue;
        margin-top: .5rem;
      }
    }
    a {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      color: $srs-blue;
      text-decoration: none;
      margin-top: 1rem;
      &:after {
        content: '';
        display: inline-block;
        height: 2rem;
        width: 2rem;
        background-image: url(../images/link-arrow.svg);
        background-size: 2rem;
        background-position: center center;
        margin-left: .5rem;
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 2rem;
    left: 2rem;
    right: 2rem;
    bottom: 2rem;
    z-index: 1;
    border: 1px solid $srs-blue;
    background: transparent;
  }
}

.commercial-blocks {
  background: lighten($gray210,8%);
  padding: 4rem;
  .content-wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
            justify-content: center;
    .link-card {
      flex-basis: 20%;
      -webkit-box-flex: 1;
              flex-grow: 1;
      min-width: 200px;
      padding-bottom: 5.5rem;
      margin: .5rem;
      max-width: 400px;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
              flex-direction: column;
      overflow: hidden;
      .item-image {
        min-height: 10rem;
        width: calc(100% + 4rem);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: -2rem;
      }
      .item-content {
        margin-top: 3.5rem;
      }
      h4 {
        color: $srs-deep-blue;
      }
      p {
        font-weight: 300;
        font-size: .9rem;
      }
      .fixed-bottom-link {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1.25rem 2rem;
        border-top: 1px solid $gray210;
        color: $srs-blue;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        -webkit-box-pack: justify;
                justify-content: space-between;
        &:after {
          content: '';
          display: inline-block;
          height: 2rem;
          width: 2rem;
          background-image: url(../images/link-arrow-dark.svg);
          background-size: 2rem;
          background-position: center center;
          margin-left: .5rem;
        }
      }
    }
  }
}


// Responsive Adjustments

@media (max-width: 1024px) {
  .about-srs {
    .left-col {
      min-width: 160px;
      img {
        width: 150px;
      }
    }
  }
}

@media (max-width: 768px) {
  .about-srs {
    flex-wrap: wrap;
    .left-col {
      text-align: center;
      max-width: 100%;
      flex-basis: 100%;
      -webkit-box-flex: 1;
              flex-grow: 1;
      padding-top: 5rem;
    }
  }
}

@media (max-width: 480px) {
  .about-srs {
    .left-col, .right-col {
      padding: 3rem;
      img {
        width: 100%;
        max-width: 120px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 1rem;
      left: 1rem;
      right: 1rem;
      bottom: 1rem;
    }
  }
}
