// Events Page Styles

.events-page {
  .events-cards .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    .event-card {
      display: flex;
      flex-direction: column;
      flex-basis: 415px;
      min-height: 384px;
      padding: 0;
      overflow: hidden;
      .image-wrapper {
        width: 100%;
        height: 170px;
        position: relative;
        background-color: $gray210;
        margin-bottom: 0.25rem;
        img {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .open-registration-tag {
          position: absolute;
          bottom: -0.75rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .card-content {
        padding: 1.5rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        gap: 0.75rem;
        .card-row {
          gap: 0.75rem;
          display: flex;
          flex-direction: column;
          h4 {
            font-size: 18px;
            color: $srs-deep-blue;
            font-weight: 600;
          }
          p {
            font-size: 14px;
            color: $gray90;
            margin: 0;
          }
          .date {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.75rem;
          }
          .location {
            font-size: 14px;
            color: $gray90;
          }
          & ~ .card-row:before {
              content: "";
              display: block;
              width: 100%;
              height: 1px;
              background-color: $gray240;
              margin: 0.5rem 0;
            }
        }
      }
    }
  }
}

// Single Event Page Styles

.single-event-hero {
  .location-card {
    display: flex;
    flex-direction: column;
    max-width: 526px;
    margin-left: auto;
    margin-bottom: -240px;
    padding: 14px;
    gap: 2rem;
    .location-img-wrapper {
      position: relative;
      border-radius: $radius;
      overflow: hidden;
      height: 176px;
      img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .location-content {
      display: flex;
      gap: 1rem;
      color: $srs-deep-blue;
      .text-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        h4 {
          font-size: 18px;
          font-weight: 600;
          margin: 0;
        }
        .location-address {
          flex-grow: 1;
          font-size: 1rem;
        }
        .open-registration-tag {
          justify-self: flex-end;
          width: max-content;
          text-decoration: none;
          &.closed {
            background-color: $gray150;
          }
        }
      }
      .maps-embbed {
        margin-left: auto;
      }
      @media (max-width: 440px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .open-registration-tag,
        .maps-embbed {
          margin: 0 auto;
        }
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      max-width: calc(100% - 2rem);
    }
  }
}

.single-event-page {
  background-color: #f5f5f5;

  .intro-row {
    padding-left: 4rem;
    padding-bottom: 8rem;
    @media (max-width: 1440px) {
      padding-left: 2rem;
    }
    .intro-text {
      border-left: 4px solid $gray210;
      padding: 0.5rem 0rem 0.5rem 1.5rem;
      max-width: 600px;
      width: calc(100% - 600px);
      @media (max-width: 1020px) {
        width: calc(100% - 500px);
      }
      @media (max-width: 768px) {
        width: calc(100% - 2rem);
        margin-top: 12rem;
      }
    }
  }
  .program-row {
    background-color: rgba(187, 206, 225, 0.15);
    padding-bottom: calc(120px + 4rem);
    h3 {
      margin-bottom: 2rem;
    }
    .tables-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .single-table-wrapper {
        .day-pre {
          font-size: 0.75rem;
          font-weight: 600;
          text-transform: uppercase;
          color: $srs-gray;
          margin-bottom: 0.5rem;
        }
        h4 {
          font-size: 28px;
          margin-bottom: 1.5rem;
          color: $srs-deep-blue;
          @media (max-width: 620px) {
            font-size: 1.25rem;
          }
        }
        .activities-wrapper {
          .activity-row {
            display: flex;
            align-items: center;
            &:nth-child(odd) {
              background-color: rgba(0, 178, 227, 0.08);
            }
            .program-cell {
              padding: 1.5rem 14px;
              p {
                margin: 0;
                & ~ p {
                  margin-top: 0.25rem;
                }
              }
            }
            .time-col {
              flex-basis: 160px;
              flex-shrink: 0;
              font-size: 1rem;
              font-weight: 600;
              color: $srs-deep-blue;
            }
            .activity-col {
              flex-grow: 1;
              font-size: 1rem;
              font-weight: 300;
              color: $gray90;
              text-align: left;
            }
            @media (max-width: 620px) {
              flex-direction: column;
              align-items: flex-start;
              .time-col {
                flex-basis: auto;
                padding-bottom: 0;
              }
              .activity-col {
                text-align: left;
                padding-top: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
  .registration-row {
    background-color: white;
    padding: 4rem 2rem;
    .registration-card {
      margin-top: -120px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
      padding-right: 0;
      .left-col {
        flex-basis: 0;
        flex-grow: 1;
      }
      .right-col {
        flex-basis: 480px;
        max-width: 500px;
        flex-grow: 1;
        margin-right: -1rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .register-cta-btn {
          display: flex;
          flex-direction: column;
          position: relative;
          gap: 0.75rem;
          text-decoration: none;
          color: white;
          background-color: $srs-deep-blue;
          padding: 1.5rem 6rem 1.5rem 2rem;
          border-radius: $radius;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
          transition: 0.2s ease-in-out all;
          &:hover {
            margin-top: -0.25rem;
            margin-bottom: 0.25rem;
            box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);
          }
          h4, .sub-text {
            max-width: 320px;
          }
          h4 {
            font-size: 18px;
            font-weight: 600;
          }
          .sub-text {
            color: rgba(255,255,255,0.5);
            font-weight: 200;
          }
          &:after {
            content: "";
            display: inline-block;
            height: 2rem;
            width: 2rem;
            min-height: 2rem;
            min-width: 2rem;
            background-image: url(../images/link-arrow.svg);
            background-size: 2rem;
            background-position: center center;
            margin-left: 0.75rem;
            position: absolute;
            right: 2rem;
            top: 2rem;
          }
          &.closed {
            background-color: $gray90;
            &:hover {
              margin-top: 0;
              box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
            }
            &:after {
              opacity: 0.4;
            }
          }
        }
        .details {
          padding-left: 2rem;
          font-size: 1rem;
          display: flex;
          flex-direction: column;
          .location-name {
            font-weight: 600;
          }
          .date {
            font-weight: 300;
          }
        }
      }
      @media (max-width: 920px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 2rem;
        .right-col {
          margin-right: 0;
          flex-basis: auto;
        }
      }
      @media (max-width: 460px) {
        .right-col {
          margin: 0 -3rem;
          .register-cta-btn h4 {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .venue-row {
    background-color: white;
    padding-top: 0;
    .venue-details {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: space-between;
      .left-col {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 520px;
        max-width: 640px;
        padding-left: 1rem;
        padding-top: 1rem;
      }
      .right-col {
        flex-basis: 30%;
        min-width: 240px;
        max-width: 520px;
        flex-grow: 1;
        img {
          width: 100%;
          height: 240px;
          border-radius: $radius;
          overflow: hidden;
          object-fit: cover;
        }
      }
      @media (max-width: 768px) {
        .left-col, .right-col {
          flex-basis: 100%;
          max-width: 100%;
          min-width: 0;
        }
      }
    }
  }
  .video-row {
    background-color: #f5f5f5;
    .recordings-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;
      padding-top: 1rem;
      .recording-card {
        flex-basis: 40%;
        flex-grow: 1;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          font-size: 1.25rem;
          font-weight: 500;
          margin-bottom: 0.5rem;
        }
        .speaker {
          font-size: 1rem;
          color: $gray90;
          font-weight: 300;
          margin-bottom: 0.5rem;
        }
        .slidesLink {
          display: inline-block;
          margin-bottom: 1rem;
        }
        @media (max-width: 768px) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }
  }
  .gallery-row {
    background-color: rgba(187, 206, 225, 0.15);
    .images-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;
      padding-top: 1rem;
      img {
        flex-basis: 30%;
        flex-grow: 1;
        max-width: 33%;
        border-radius: $radius;
        overflow: hidden;
        object-fit: cover;
        height: 200px;
        cursor: pointer;
        position: relative;
        transition: opacity 0.3s ease-in-out;
        &:hover {
          opacity: 0.8;
        }
        @media (max-width: 920px) {
          flex-basis: 48%;
          max-width: 48%;
        }
        @media (max-width: 460px) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.single-event-outer-wrapper {
  .link-blocks {
    border-top: 2px solid $gray240;
  }
}

// Reusable Styles
.open-registration-tag {
  background-color: #3C5670;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.5rem;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
}