// Home Page Styles

.home-page {
  .benefits-row {
    background: $srs-blue;
    color: white;
    text-align: center;
    padding-bottom: 2rem;
    .content-wrapper {
      display: -webkit-box;
      display: flex;
      .item-wrapper {
        flex-basis: 20%;
        -webkit-box-flex: 1;
        flex-grow: 1;
        margin: 2rem;
        -webkit-box-pack: justify;
        justify-content: space-between;
        position: relative;
        margin-top: -9rem;
        z-index: 4;
        .icon-wrapper {
          width: 9rem;
          height: 9rem;
          background: white;
          border-radius: 9rem;
          margin: 0 auto 1.5rem;
          @include box-shadow(8px, 0.15);
          svg {
            width: 100%;
            height: 100%;
          }
        }
        .item-text {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.4;
          margin: 0 auto;
          font-weight: 500;
        }
      }
    }
  }
  .trusted-row {
    text-align: center;
    h3 {
      margin-bottom: 2rem;
    }
    .swiper-container {
      position: relative;
      background: white;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        background: rgba(0, 0, 0, 0.06);
        z-index: 5;
      }
      .swiper-button-next,
      .swiper-button-prev {
        z-index: 8;
      }
      .swiper-button-next {
        background-image: url(../images/slider-arrow-right.svg);
      }
      .swiper-button-prev {
        background-image: url(../images/slider-arrow-left.svg);
      }
    }
    .logos-wrapper {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      flex-wrap: wrap;
      .image-wrapper {
        margin: 1.5rem 2.5rem;
        flex-basis: 140px;
        img {
          width: 100%;
          border-radius: $radius;
        }
      }
    }
  }
}

// Responsive Adjustments

@media (max-width: 1024px) {
  .home-page {
    .benefits-row {
      .content-wrapper {
        .item-wrapper {
          margin-left: 1rem;
          margin-right: 1rem;
          .item-text {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

@media (max-width: 910px) {
  .home-page {
    .benefits-row {
      .content-wrapper {
        .item-wrapper {
          margin-top: -6rem;
          .icon-wrapper {
            width: 8rem;
            height: 8rem;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .home-page {
    .benefits-row {
      .content-wrapper {
        flex-wrap: wrap;
        margin-top: -6rem;
        .item-wrapper {
          flex-basis: 40%;
          margin-bottom: 2rem;
          margin-top: 0;
          .icon-wrapper {
            width: 8rem;
            height: 8rem;
          }
        }
      }
    }
    .trusted-row {
      .logos-wrapper {
        .image-wrapper {
          flex-basis: 100px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    .benefits-row {
      .content-wrapper {
        flex-wrap: wrap;
        margin-top: -6rem;
        .item-wrapper {
          flex-basis: 80%;
          margin-bottom: 2rem;
          margin-top: 0;
          .icon-wrapper {
            width: 8rem;
            height: 8rem;
          }
        }
      }
    }
    .trusted-row {
      .logos-wrapper {
        .image-wrapper {
          flex-basis: 80px;
        }
      }
    }
  }
}
