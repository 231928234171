// Performance Page Styles

.performance-page {
  .circle {
    width: .5rem;
    height: .5rem;
    border-radius: .5rem;
    display: inline-block;
    margin-right: .25rem;
    &.large {
      width: 1rem;
      height: 1rem;
      margin-right: .5rem;
    }
    &.pass {
      background-color: $pass-dark;
    }
    &.fail {
      background-color: $fail-dark;
    }
  }
  .intro-row {
    background: white;
    padding: 0 4rem;
    position: relative;
    max-width: 1312px;
    margin: 0 auto;
    .intro-content {
      position: absolute;
      top: -10rem;
      right: 4rem;
      background: white;
      max-width: 560px;
      padding: 1rem 1.5rem;
      font-size: .9rem;
      border-radius: $radius;
      z-index: 3;
      @include box-shadow(6px,0.15);
    }
  }
  .stats-row {
    background: rgba(0,0,0,0.05);
    h3 {
      margin-bottom: 2rem;
    }
    .content-wrapper .stats-wrapper {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: center;
              justify-content: center;
      .total-item {
        flex-basis: 10%;
        -webkit-box-flex: 1;
                flex-grow: 1;
        -webkit-box-pack: justify;
                justify-content: space-between;
        text-align: center;
        background: white;
        margin: .5rem;
        padding: 1rem 1rem 1.5rem;
        border-radius: $radius;
        @include box-shadow(6px,0.15);
        .data {
          font-size: 3rem;
          color: $srs-deep-blue;
          font-weight: 300;
          margin-bottom: .25rem;
        }
        .label {
          text-transform: uppercase;
          font-size: .8rem;
        }
        &.final-status {
          .data {
            display: -webkit-box;
            display: flex;
            -webkit-box-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                    justify-content: center;
          }
        }
      }
    }
  }
  .tests-row {
    .filter-wrapper {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      padding: .5rem 0;
      background: rgba(0,0,0,0.05);
      border-radius: $radius;
      margin-top: -1.5rem;
      .main-title {
        padding-right: 2rem;
        padding-left: .5rem;
        font-weight: 500;
        font-size: 1.1rem;
      }
      .filter-unit {
        padding-right: 2rem;
        .label {
          margin-right: .25rem;
        }
        select {
          min-width: 100px;
        }
      }
    }
    .test-wrapper {
      margin-bottom: 2rem;
      padding: 1.5rem 1rem 1rem;
      position: relative;
      .test-heading {
        font-size: 1.6rem;
        margin-bottom: .5rem;
        font-weight: 300;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        cursor: pointer;
        &:before {
          content: '';
          width: 25px;
          height: 25px;
          background-image: url(../images/icn_show_more.svg);
          background-size: 25px 25px;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: .5rem;
          position: absolute;
          top: 1.5rem;
          right: 1rem;
        }
        a {
          font-size: 1rem;
          margin-left: 1rem;
        }
      }
      .test-content {
        visibility: hidden;
        opacity: 0;
        height: 0;
        overflow: hidden;
        -webkit-transition: 0.5s ease-in-out all;
        transition: 0.5s ease-in-out all;
      }
      &.expanded {
        .test-heading {
          margin-bottom: 1.5rem;
          &:before {
            background-image: url(../images/icn_show_less.svg);
          }
        }
        .test-content {
          visibility: visible;
          opacity: 1;
          height: auto;
        }
      }
      .test-summary {
        padding: 1rem;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
                justify-content: space-between;
        margin-bottom: .5rem;
        border-radius: $radius;
        &.pass {
          background-color: $pass-light;
        }
        &.fail {
          background-color: $fail-light;
        }
        .summary-item {
          font-size: 1rem;
          color: $srs-deep-blue;
          h5 {
            margin-bottom: .25rem;
            font-size: .8rem;
            color: $srs-gray;
          }
        }
      }
      .test-table {
        text-align: left;
        background-color: rgba(0,0,0,0.035);
        width: 100%;
        border-radius: $radius;
        padding: .5rem;
        font-size: .9rem;
        th {
          font-weight: 400;
          color: $srs-deep-blue;
          &:after {
            content: '';
            display: block;
            height: 2px;
            width: 1rem;
            background: $srs-blue;
            margin-top: .5rem;
          }
        }
        th, td {
          padding: .75rem;
        }
        th.result, td.result {
          width: 280px;
          pre {
            overflow: auto;
            width: 280px;
            max-height: 200px;
            margin: 0 2rem 0 0;
            background: rgba(0,0,0,0.025);
            padding: .5rem;
            border-radius: $radius;
            font-size: .8rem;
          }
        }
      }
    }
    .mobile-hideout {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .performance-page {
    .intro-row {
      .intro-content {
        max-width: 460px;
      }
    }
    .stats-row {
      .content-wrapper {
        .total-item {
          margin: 2rem;
        }
      }
    }
    .tests-row {
      padding: 4rem 2rem;
      .test-wrapper {
        .test-table {
          font-size: .8rem;
        }
      }
    }
  }
}

@media (max-width: 910px) {
  .performance-page {
    .intro-row {
      background: white;
      padding: 2rem;
      .intro-content {
        max-width: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        position: static;
        box-shadow: none;
      }
    }
    .tests-row {
      .desktop-hideout {
        display: none;
      }
      .mobile-hideout {
        display: block;
      }
      .test-wrapper-mobile {
        margin-bottom: 2rem;
        padding: 1.5rem 1rem 1rem;
        .test-heading {
          font-size: 1.6rem;
          margin-bottom: .5rem;
          font-weight: 300;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
                  align-items: center;
          -webkit-box-pack: justify;
                  justify-content: space-between;
          cursor: pointer;
          &:after {
            content: '';
            width: 25px;
            height: 25px;
            background-image: url(../images/icn_show_more.svg);
            background-size: 25px 25px;
            background-position: center;
            background-repeat: no-repeat;
            margin-left: .5rem;
            min-width: 25px;
            min-width: 25px;
          }
          a {
            font-size: 1rem;
            margin-left: 1rem;
          }
        }
        .test-content {
          visibility: hidden;
          opacity: 0;
          height: 0;
          overflow: hidden;
          -webkit-transition: 0.5s ease-in-out all;
          transition: 0.5s ease-in-out all;
        }
        &.expanded {
          .test-heading {
            margin-bottom: 1rem;
            &:after {
              background-image: url(../images/icn_show_less.svg);
            }
          }
          .test-content {
            visibility: visible;
            opacity: 1;
            height: auto;
          }
        }
        .test-summary {
          display: -webkit-box;
          display: flex;
          flex-wrap: wrap;
          border-radius: $radius;
          margin-bottom: .5rem;
          &.pass {
            background-color: $pass-light;
          }
          &.fail {
            background-color: $fail-light;
          }
          .summary-item {
            font-size: 1rem;
            color: $srs-deep-blue;
            padding: 1rem;
            h5 {
              margin-bottom: .25rem;
              font-size: .8rem;
              color: $srs-gray;
            }
          }
        }
        .test-entry {
          padding: .5rem;
          background: rgba(0,0,0,0.05);
          border-radius: $radius;
          display: -webkit-box;
          display: flex;
          flex-wrap: wrap;
          & ~ .test-entry {
            margin-top: .5rem;
          }
          & > div {
            padding: 1rem;
            &.result {
              flex-basis: 100%;
              width: 100%;
              pre {
                overflow: auto;
                max-height: 250px;
                margin: 0;
                background: rgba(0,0,0,0.025);
                padding: .5rem;
                border-radius: $radius;
                display: block;
              }
            }
            h5 {
              font-weight: 400;
              color: $srs-deep-blue;
              &:after {
                content: '';
                display: block;
                height: 2px;
                width: 1rem;
                background: $srs-blue;
                margin-top: .5rem;
                margin-bottom: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .performance-page {
    .tests-row {
      .filter-wrapper {
        .main-title {
          flex-basis: 100%;
          margin-bottom: .5rem;
        }
        .filter-unit {
          margin-left: .5rem;
          flex-basis: 20%;
          -webkit-box-flex: 1;
                  flex-grow: 1;
          padding-right: .5rem;
          font-size: .9rem;
          & ~ .filter-unit {
            margin-left: 0;
          }
          select {
            width: 100%;
            font-size: .9rem;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .performance-page {
    .stats-row {
      .content-wrapper {
        .total-item {
          margin: 1rem;
        }
      }
    }
    .tests-row {
      padding: 4rem 1rem;
      .filter-wrapper {
        .filter-unit {
          flex-basis: 100%;
          margin: .25rem .5rem;
          display: -webkit-box;
          display: flex;
          flex-wrap: wrap;
          span {
            flex-basis: 100%;
            margin-bottom: .25rem;
          }
          & ~ .filter-unit {
            margin: .25rem .5rem;
          }
          select {
            width: 100%;
          }
        }
      }
      .test-wrapper-mobile {
        margin-bottom: 1rem;
        padding: 1rem .75rem .75rem;
        .test-heading {
          font-size: 1rem;
        }
        .test-summary {
          padding: 0;
          .summary-item {
            padding: .75rem;
            font-size: .9rem;
          }
        }
        .test-entry {
          font-size: .9rem;
          padding: 0;
          & > div {
            padding: .75rem;
            &.result {
              pre {
                font-size: .8rem;
              }
            }
            &.testcase {
              flex-basis: 100%;
            }
            h5 {
              &:after {
                margin-bottom: .5rem;
              }
            }
          }
        }
      }
    }
  }
}
