// srsRAN Website Styles

// Variables

$srs-blue: #00b2e3;
$srs-green: #07e8cb;
$srs-gray: #758592;
$srs-deep-blue: #1a283d;

$deeper-blue: #0c1728;
$pass-light: #eaf8f5;
$pass-dark: $srs-green;
$fail-light: #fee8e8;
$fail-dark: #f57a7a;

$dark: #222222;
$gray30: #1e1e1e;
$gray60: #3c3c3c;
$gray90: #5a5a5a;
$gray120: #787878;
$gray150: #969696;
$gray180: #b4b4b4;
$gray210: #d2d2d2;
$gray240: #f0f0f0;

$radius: 5px;

@mixin box-shadow($spread, $opacity) {
  box-shadow: 0 0 $spread 0 rgba(0, 0, 0, $opacity);
}

// Font & Text

// Hosting Ubuntu locally to force correct rendering on Ubuntu

@font-face {
  font-family: "srsRAN Ubuntu";
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Ubuntu-Light.ttf) format("truetype");
}

@font-face {
  font-family: "srsRAN Ubuntu";
  font-style: italic;
  font-weight: 300;
  src: url(../fonts/Ubuntu-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "srsRAN Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Ubuntu-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "srsRAN Ubuntu";
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/Ubuntu-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "srsRAN Ubuntu";
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Ubuntu-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "srsRAN Ubuntu";
  font-style: italic;
  font-weight: 500;
  src: url(../fonts/Ubuntu-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "srsRAN Ubuntu";
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Ubuntu-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "srsRAN Ubuntu";
  font-style: italic;
  font-weight: 700;
  src: url(../fonts/Ubuntu-BoldItalic.ttf) format("truetype");
}

body {
  font-family: "srsRAN Ubuntu", sans-serif;
  font-weight: 300;
}

h1,
h2,
h3 {
  &.with-line {
    margin-bottom: 1rem;
    &:after {
      content: "";
      display: block;
      height: 3px;
      width: 2.5rem;
      background: $srs-blue;
      margin-top: 0.5rem;
    }
    &.centered {
      text-align: center;
      &:after {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: 2rem;
  margin-bottom: 0.25rem;
}

h4 {
  font-size: 1.2rem;
  margin-bottom: 0.25rem;
}

p {
  margin: 0.5rem 0;
}

a {
  color: $srs-blue;
  font-weight: 400;
}

// General Layout

html {
  font-size: 16px;
  color: $srs-gray;
  background-color: $gray240;
}

.content-wrapper {
  max-width: 1312px;
  margin: 0 auto;
}

.row {
  padding: 4rem;
}

.hidden {
  display: none;
}

// Reusable Components

.card {
  background: white;
  border-radius: $radius;
  padding: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: inherit;
  display: block;
  top: 0;
  -webkit-transition: 0.2s ease-in-out all;
  transition: 0.2s ease-in-out all;
  &.with-hover {
    position: relative;
    &:hover {
      top: -3px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    }
  }
}

.button {
  border: 2px solid $srs-blue;
  padding: 0.5rem 1.5rem;
  border-radius: 10rem;
  display: inline-block;
  color: $srs-deep-blue;
  text-decoration: none;
  background-color: transparent;
  -webkit-transition: 0.2s ease-in-out all;
  transition: 0.2s ease-in-out all;
  text-align: center;
  &:hover {
    background-color: $srs-blue;
    color: white;
  }
  &.sub-button {
    background: white;
    border-color: white;
    display: inline-block;
    padding-left: 3rem;
    position: relative;
    margin-top: 0.5rem;
    .btn-icon {
      background-position: center;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 1rem;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-right: 1rem;
    }
    &:hover {
      border-color: $srs-blue;
      background-color: $srs-blue;
      color: $srs-deep-blue;
    }
  }
}

// Name change top bar notice
.name-change-topbar {
  background: $srs-blue;
  display: block;
  text-align: center;
  padding: 0.5rem 1rem;
  min-height: 2rem;
  color: white;
  a {
    color: white;
  }
}

// Header & Page Heros

header {
  padding: 0.5rem 2rem;
  min-height: 4rem;
  background-color: transparent;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  &.has-topbar {
    margin-top: 3.5rem;
  }
  .content-wrapper {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid $deeper-blue;
    .left-col {
      flex-basis: 20%;
      min-width: 160px;
      .brand {
        font-size: 1.5rem;
        font-weight: 700;
        text-decoration: none;
        width: 110px;
        height: 34px;
        display: block;
        svg {
          width: 110px;
          height: 34px;
          margin-top: 2px;
        }
      }
    }
    .right-col {
      flex-basis: 80%;
      -webkit-box-flex: 1;
      flex-grow: 1;
      text-align: right;
      .site-nav {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        align-items: center;
        margin-right: 1rem;
        font-weight: 300;
        a {
          font-size: 0.9rem;
          line-height: 1;
          text-decoration: none;
          color: white;
          display: block;
          position: relative;
          padding: 1.5rem 0;
          &.active {
            font-weight: 500;
          }
          &.home {
            width: 1rem;
            height: 1rem;
          }
          .home-icon {
            width: 1rem;
            height: 1rem;
          }
          & ~ a {
            margin-left: 1.5rem;
          }
          &:after {
            content: "";
            display: block;
            height: 3px;
            width: 100%;
            background: $srs-blue;
            position: absolute;
            top: 3rem;
            opacity: 0;
            -webkit-transition: 0.2s ease-in-out all;
            transition: 0.2s ease-in-out all;
          }
          &:hover:after,
          &.active:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.inner-hero {
  padding: 8rem 2rem 3rem;
  color: white;
  background-image: url(../images/hero-image.png);
  background-size: cover;
  background-position: center center;
  background-color: $srs-deep-blue;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
  .content-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    z-index: 2;
    .left-col {
      -webkit-box-flex: 1;
      flex-grow: 1;
      flex-basis: 50%;
      padding-right: 2rem;
      .buttons-wrapper {
        display: inline-flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      a.sublink {
        text-decoration: none;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: white;
        font-size: 1.1rem;
        &:after {
          content: "";
          display: inline-block;
          height: 2rem;
          width: 2rem;
          min-height: 2rem;
          min-width: 2rem;
          background-image: url(../images/link-arrow.svg);
          background-size: 2rem;
          background-position: center center;
          margin-left: 0.75rem;
        }
      }
    }
    .right-col {
      min-width: 520px;
      flex-basis: 37%;
      .get-srsran-snippet {
        background: rgba(255, 255, 255, 0.2);
        border-radius: $radius;
        padding: 1.5rem 2rem;
        position: relative;
        @include box-shadow(6px, 0.05);
        h4 {
          text-align: center;
          margin-bottom: 1.25rem;
          font-size: 1rem;
        }
        .code-wrapper {
          background: $deeper-blue;
          padding: 1.25rem;
          border-radius: $radius;
          font-size: 0.85rem;
          > div:before {
            content: "$";
            color: rgba(255, 255, 255, 0.5);
            margin-right: 0.25rem;
          }
          > div ~ div {
            margin-top: 0.25rem;
          }
        }
        .corner-wrapper {
          position: absolute;
          top: -0.25rem;
          left: -0.25rem;
          border-radius: $radius;
          overflow: hidden;
          @include box-shadow(6px, 0.05);
          .highlight-corner {
            border-top: 5.75rem solid $srs-blue;
            border-right: 5.75rem solid transparent;
            position: relative;
            &:after {
              content: ">_";
              position: absolute;
              top: -5rem;
              left: 0.75rem;
              color: $srs-deep-blue;
              font-size: 1.25rem;
              font-weight: 500;
            }
          }
        }
      }
      .cta-event {
        display: flex;
        gap: 1.5rem;
        padding: 1.5rem 1rem;
        max-width: 400px;
        margin-left: auto;
        .event-cta-image {
          width: 66px;
          object-fit: contain;
          object-position: top center;
          @media (max-width: 400px)
          {
            display: none;
          }
        }
        .text-wrapper {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          h4.cta-title {
            font-size: 1rem;
            font-weight: 600;
            color: $srs-blue;
          }
          span.cta-subtitle {
            font-size: 14px;
            font-weight: 200;
            color: $srs-gray;
          }
        }
        &:after {
          content: "";
          display: inline-block;
          height: 2rem;
          width: 2rem;
          min-height: 2rem;
          min-width: 2rem;
          background-image: url(../images/link-arrow-dark.svg);
          background-size: 2rem;
          background-position: center center;
          margin-left: 0.75rem;
          @media (max-width: 400px)
          {
            display: none;
          }
        }
      }
    }
  }
  h2 {
    font-size: 2.5rem;
    padding-bottom: 0.5rem;
    position: relative;
    &:after {
      content: "";
      display: block;
      height: 3px;
      width: 2.5rem;
      background: $srs-blue;
      margin-top: 1rem;
    }
  }
  &.home-hero {
    min-height: 20rem;
    padding-top: 10rem;
    padding-bottom: 4rem;
    .content-wrapper {
      .left-col {
        max-width: 640px;
        h1 {
          color: $srs-blue;
          font-weight: 300;
          font-size: 1.75rem;
        }
        h2 {
          font-size: 3.2rem;
        }
        .subheading {
          font-size: 1rem;
        }
        .hero-social-icons {
          display: flex;
          gap: 1rem;
          margin-top: 1rem;
          .social-icon {
            width: 32px;
            height: 32px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            transition: opacity 0.3s;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}

// CTAs
.cta-buttons-wrapper {
  display: flex;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: $radius;
  padding: 1.5rem 2rem;
  min-height: 10rem;
  @include box-shadow(6px, 0.05);
  .cta-button {
    display: flex;
    width: 40%;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    .cta-img {
      position: relative;
      width: 120px;
      height: 120px;
      border-radius: 120px;
      margin-bottom: -24px;
      background-color: white;
      background-size: 56px;
      background-position: center center;
      background-repeat: no-repeat;
      outline: 1px solid $srs-blue;
      outline-offset: -0.25rem;
      box-shadow: 0 2px 6px 0.25rem rgba(0, 0, 0, 0.25);
      top: 0;
      transition: box-shadow 0.3s, top 0.3s;
      &.fiveG {
        background-image: url(../images/5g-cta.svg);
      }
      &.fourG {
        background-image: url(../images/4g-cta.svg);
      }
      &:before {
        content: "";
        position: absolute;
        inset: 0.5rem;
        border: 1px solid $srs-blue;
        border-radius: 120px;
        opacity: 0.5;
      }
      &:after {
        content: "";
        position: absolute;
        inset: 0.75rem;
        border: 1px solid $srs-blue;
        border-radius: 120px;
        opacity: 0.25;
      }
    }
    .cta-box {
      display: flex;
      border-radius: $radius;
      background-color: $srs-blue;
      color: white;
      padding: 2rem 1rem 1.5rem;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s;
    }

    &:hover {
      .cta-img {
        top: -0.25rem;
        box-shadow: 0 4px 6px 0.25rem rgba(0, 0, 0, 0.25);
      }
      .cta-box {
        background-color: $srs-deep-blue;
      }
    }
  }
}

// Footer Link Blocks

.link-blocks {
  background: white;
  .content-wrapper {
    display: -webkit-box;
    display: flex;
    .link-block {
      flex-basis: 30%;
      & ~ .link-block {
        margin-left: 1.5rem;
        padding-left: 3rem;
        border-left: 2px solid $gray210;
      }
      h5 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        font-weight: 300;
        &:after {
          content: "";
          display: block;
          height: 2px;
          width: 2rem;
          background: $srs-blue;
          margin-top: 0.5rem;
          margin-bottom: 1rem;
        }
      }
      p {
        max-width: 280px;
      }
      .button {
        margin-top: 1rem;
      }
    }
  }
}

// Bottom Footer

footer {
  background: $srs-deep-blue;
  border-top: 4px solid $srs-blue;
  color: white;
  padding: 3rem 2rem 6rem;
  position: relative;
  h5 {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 300;
  }
  .content-wrapper {
    display: -webkit-box;
    display: flex;
    .logo-col {
      flex-basis: 200px;
      .brand {
        font-size: 1.5rem;
        font-weight: 700;
        text-decoration: none;
        width: 110px;
        height: 34px;
        display: block;
        svg {
          width: 110px;
          height: 34px;
        }
      }
    }
    .github-col {
      flex-basis: 500px;
      .btn-wrapper {
        display: inline-block;
        .widget a.btn {
          background-color: $deeper-blue !important;
        }
        & ~ .btn-wrapper {
          margin-left: 0.5rem;
        }
      }
    }
    .social-col {
      flex-basis: 300px;
    }
  }
  .maintained-by {
    background: $deeper-blue;
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 0.8rem;
    padding: 1rem 2rem;
  }
}

// Lightbox

.lightbox {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &.visible {
    pointer-events: all;
    opacity: 1;
  }
  .lightbox-content {
    background: white;
    border-radius: $radius;
    padding: 2rem;
    position: absolute;
    inset: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: $radius;
    }
    .close-btn {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 5px;
      padding: 0.5rem;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.25);
      transition: background-color 0.3s ease-in-out;
      border-radius: 50%;
      &:hover {
        background-color: rgba(0, 0, 0, 0.25);
      }
      &:before {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        background-image: url(../images/close.svg);
        background-size: 1.5rem;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
}

// Responsive Adjustments

@media (max-width: 1024px) {
  h3 {
    font-size: 1.5rem;
  }
  .inner-hero,
  .inner-hero.home-hero {
    .content-wrapper {
      .right-col {
        min-width: 400px;
        .get-srsran-snippet {
          h4 {
            font-size: 0.9rem;
          }
          .corner-wrapper .highlight-corner {
            border-top: 5rem solid #00b2e3;
            border-right: 5rem solid transparent;
            &:after {
              top: -4.5rem;
            }
          }
        }
      }
    }
  }
  .link-blocks {
    .content-wrapper {
      .link-block {
        h5 {
          font-size: 1.25rem;
        }
      }
    }
  }
}

@media (max-width: 910px) {
  .row {
    padding: 2rem;
  }
  .inner-hero,
  .inner-hero.home-hero {
    .content-wrapper {
      .left-col {
        max-width: 360px;
        h2 {
          font-size: 2.25rem;
        }
        .subheading {
          font-size: 0.9rem;
        }
      }
    }
  }
  .inner-hero.home-hero {
    padding-bottom: 2rem;
  }
  footer {
    .content-wrapper {
      flex-wrap: wrap;
      .logo-col {
        flex-basis: 160px;
      }
      .social-col {
        margin-top: 2rem;
        flex-basis: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  header {
    .content-wrapper {
      .left-col {
        min-width: 110px;
        a.brand {
          svg {
            width: 110px;
            height: 34px;
            margin-right: 1rem;
          }
        }
      }
      .right-col {
        .site-nav {
          a ~ a {
            margin-left: 1rem;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  .inner-hero,
  .inner-hero.home-hero {
    .content-wrapper {
      flex-wrap: wrap;
      -webkit-box-pack: center;
      justify-content: center;
      .left-col {
        margin-bottom: 2rem;
        max-width: 100%;
        flex-basis: 100%;
      }
      .right-col {
        max-width: 100%;
        flex-basis: 100%;
        min-width: auto;
      }
    }
  }
  .inner-hero.home-hero {
    padding-bottom: 8rem;
  }
  .link-blocks {
    .content-wrapper {
      flex-wrap: wrap;
      .link-block {
        flex-basis: 80%;
        -webkit-box-flex: 1;
        flex-grow: 1;
        padding: 1rem;
        & ~ .link-block {
          border: 0;
          margin: 0;
          padding: 1rem;
          margin-top: 1rem;
          padding-top: 2rem;
          border-top: 2px solid $gray210;
        }
        p {
          max-width: 100%;
        }
      }
    }
  }
  footer {
    .content-wrapper {
      flex-wrap: wrap;
      .github-col {
        flex-basis: 100%;
        margin-top: 2rem;
      }
    }
  }
}

@media (max-width: 640px) {
  header {
    .content-wrapper {
      .left-col {
        min-width: 120px;
      }
      .right-col {
        .menu-toggle {
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 5px;
          padding: 0.5rem;
          &:hover {
            background: rgba(0, 0, 0, 0.25);
          }
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
        .site-nav {
          display: none;
        }
        .site-nav-mobile {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          background: $deeper-blue;
          padding-top: 4rem;
          color: white;
          padding-bottom: 2rem;
          @include box-shadow(6px, 0.5);
          .brand-mobile {
            width: 110px;
            height: 34px;
            position: absolute;
            top: 0.75rem;
            left: 2rem;
          }
          .menu-toggle {
            position: absolute;
            top: 1rem;
            right: 2rem;
            margin-top: -2px;
          }
          .navigation-wrapper {
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            a {
              padding: 1rem;
              background: white;
              border-radius: 5px;
              text-align: center;
              color: $srs-blue;
              text-decoration: none;
              margin: 0.5rem 2rem;
              &:hover {
                background: rgba(255, 255, 255, 0.9);
              }
              &.active {
                background: $srs-blue;
                color: white;
                &:hover {
                  background: darken($srs-blue, 5%);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .inner-hero,
  .inner-hero.home-hero {
    .content-wrapper {
      .left-col {
      }
      .right-col {
        .get-srsran-snippet {
          padding: 1rem;
          h4 {
            padding: 0 1rem;
            font-size: 1rem;
          }
          .code-wrapper {
            padding: 1rem;
            font-size: 0.8rem;
          }
          .corner-wrapper .highlight-corner {
            border-top: 3rem solid #00b2e3;
            border-right: 3rem solid transparent;
            &:after {
              top: -2.75rem;
              left: 0.4rem;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
  footer {
    .content-wrapper {
      .github-col {
        .github-buttons-wrapper {
          display: -webkit-box;
          display: flex;
          flex-wrap: wrap;
          .btn-wrapper {
            margin: 0.25rem;
          }
        }
      }
    }
  }
}
